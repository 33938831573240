import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { useAuthStore } from './auth';
import { setErrorMessage } from '~/composables';
import { useErrorStore } from '~/store/error';
import { useSuccessStore } from '~/store/success';
import { setErrorToast, setSuccessToast } from '~/composables/toast';
import { i18nGlobal } from '~/modules/i18n';
import type { Approval } from '~/types/approval';

interface UserAddress {
  street: string | null;
  house_number: string | null;
  apartment_number: string | null;
  postal_code: string | null;
  post: string | null;
  city: string | null;
  country_code: string | null;
}

interface User {
  id: number;
  company_name: string | null;
  regon: string | null;
  nip: string | null;
  pesel: string | null;
  name: string | null;
  surname: string | null;
  email: string | null;
  is_email_verified: boolean | null;
  phone_number: string | null;
  phone_prefix: string | null;
  address: UserAddress;
  correspondence_address: UserAddress;
  approvals: Array<Approval>;
  // approvals: string[] | [];
  is_agent: boolean | null;
}

interface UserAgent {
  id: number;
  name: string | null;
  last_name: string | null;
  phone: string | null;
  email: string | null;
  code: string;
  url: string | null;
  permissions: string[];
  gender: string;
}

interface UserState {
  userInfo: User;
  userSavedData: User;
  userInfoDownloaded: boolean | null;
  userAgent: UserAgent;
}

export const useUserStore = defineStore('user', {
  state(): UserState {
    return {
      userInfo: {
        id: 1,
        name: null,
        surname: null,
        email: null,
        is_email_verified: null,
        is_agent: null,
        address: {
          street: null,
          house_number: null,
          apartment_number: null,
          postal_code: null,
          post: null,
          city: null,
          country_code: null,
        },
        correspondence_address: {
          street: null,
          house_number: null,
          apartment_number: null,
          postal_code: null,
          post: null,
          city: null,
          country_code: null,
        },
        phone_number: null,
        approvals: [],
      },
      userSavedData: {
        id: 1,
        name: null,
        surname: null,
        email: null,
        is_email_verified: null,
        is_agent: null,
        address: {
          street: null,
          house_number: null,
          apartment_number: null,
          postal_code: null,
          post: null,
          city: null,
          country_code: null,
        },
        correspondence_address: {
          street: null,
          house_number: null,
          apartment_number: null,
          postal_code: null,
          post: null,
          city: null,
          country_code: null,
        },
        phone_number: null,
        approvals: [],
      },
      userInfoDownloaded: null,
      userAgent: {
        id: 1,
        name: null,
        last_name: null,
        phone: null,
        email: null,
        code: 'Cum.4834',
        url: null,
        permissions: [
          'nnw-school',
        ],
        gender: 'N',
      },
    };
  },
  actions: {
    async login(reqData: LoginRequest) {
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/login`, {
          email: reqData.email,
          password: reqData.password,
          remember: reqData.remember,
          order_uuid: reqData.orderUuid,
        });
        this.token = res.data.access_token;
        this.status = res.data.status;
        this.tokenType = res.data.token_type;
        this.isLoggedIn = res.data.access_token !== '';
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    logout() {
      if (window.localStorage.getItem('auth')) {
        window.localStorage.removeItem('auth');
      }
      this.$reset();
    },
    setProperBirthDateFormat() {
      if (this.userSavedData.birth_date) {
        this.userInfo.birth_date = new Date(this.userInfo.birth_date);
        this.userSavedData.birth_date = new Date(this.userSavedData.birth_date).toLocaleDateString().replace(/\./g, '-');
      }
    },
    async getUserData() {
      try {
        const res = await axios.get(`${import.meta.env./api}/v1/user?action=get`);

        this.userInfo = res.data.data;
        this.userSavedData = JSON.parse(JSON.stringify(res.data.data));
        this.userInfoDownloaded = true;
        this.setProperBirthDateFormat();
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        this.userInfoDownloaded = false;
        setErrorMessage(error, 'get-user');
        return Promise.reject(error);
      }
    },
    async refreshUserData() {
      try {
        const res = await axios.get(`${import.meta.env./api}/v1/user?action=refresh`);

        this.userInfo = res.data.data;
        this.userSavedData = JSON.parse(JSON.stringify(res.data.data));
        this.userInfoDownloaded = true;
        this.setProperBirthDateFormat();
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async setUserData(userData) {
      this.userInfo = userData;
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/user`, userData);
        this.userInfo = res.data.data;
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        setErrorMessage(error, 'set-user');
        return Promise.reject(error);
      }
    },
    async changeUserPassword(userPasswords) {
      const errorStore = useErrorStore();
      const successStore = useSuccessStore();
      const toast = useToast();
      errorStore.$reset();
      successStore.$reset();
      toast.clear();

      try {
        await axios.post(`${import.meta.env./api}/v1/user/password`, userPasswords);
        setSuccessToast(i18nGlobal.t('clientPanel.myPersonalData.changePasswordConfirmation'), 5000);
      } catch (error) {
        console.error(error);
        setErrorToast(error.response.data.message, 5000);
        setErrorMessage(error, 'change-pass-user');
      }
    },
    async deleteUser() {
      const authStore = useAuthStore();
      const errorStore = useErrorStore();
      const successStore = useSuccessStore();
      const toast = useToast();

      errorStore.$reset();
      successStore.$reset();
      toast.clear();

      try {
        const res = await axios.delete(`${import.meta.env./api}/v1/user`);
        setSuccessToast(i18nGlobal.t('clientPanel.myPersonalData.deleteAccount.deleteConfirmation'), 5000);
        authStore.logout();
        this.router.push('/');

        return Promise.resolve(res);
      } catch (error) {
        setErrorToast(error.response.data.message, 5000);
        setErrorMessage(error, 'remove-user');
        return Promise.reject(error);
      }
    },
    resetUserInfo() {
      this.$reset();
    },
  },
  getters: {
    getUserInfo: (state: UserState) => state.userInfo && state.userInfo,
    getUserId: (state: UserState) => state.userInfo && state.userInfo.id,
    getUserName: (state: UserState) => state.userInfo && state.userInfo.name,
    getUserEmail: (state: UserState) => state.userInfo && state.userInfo.email,
    getUserAddress: (state: UserState) => state.userInfo.address && state.userInfo.address,
    getUserCorrespondenceAddress: (state: UserState) => state.userInfo.correspondence_address && state.userInfo.correspondence_address,
    getEmailVerified: (state: UserState) => state.userInfo.is_email_verified && state.userInfo.is_email_verified,
    getUserVerified: (state: UserState) => state.userInfo.is_email_verified && state.userInfo.is_email_verified,
    isUserData: (state: UserState) => state.userInfoDownloaded && state.userInfoDownloaded,
    userIsAgent: (state: UserState) => state.userInfo && state.userInfo.is_agent,
  },
  persist: {
    storage: window.sessionStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
